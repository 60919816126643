import { type SyntheticEvent } from 'react';
import moment, { type MomentInput } from 'moment-timezone';
import { camelize, decamelize } from 'humps';

import { http, type AllProjectsToMoveProductsOption, type User } from 'js/api';

import { track, TRACKED_EVENTS } from 'js/utils/analytics';
import {
  PROJECT_STATUS_CLOSED,
  PROJECT_STATUS_LOST,
  STORED_HEADERS,
  USER_ROLE_ADMIN,
  USER_ROLE_DESIGNER,
  USER_ROLE_SALESPERSON,
} from './config';

// @TODO: should be store based function + not used
export function authenticated(): boolean {
  return !!(
    STORED_HEADERS.every((header) => localStorage.getItem(header)) &&
    localStorage.getItem('user')
  );
}

// @TODO: create strict type for headers
type Headers = { [k: string]: string | null };

export function fetchHeaders(): Headers {
  const headers: Headers = {};
  STORED_HEADERS.forEach((header) => {
    const value = localStorage.getItem(header);
    if (value) headers[header] = value;
  });
  headers['X-CSRF-Token'] = localStorage.getItem('csrf');

  return headers;
}

export async function logout() {
  // @TODO Update fields
  // track(TRACKED_EVENTS.LOGGED_OUT, {
  //   emailAddress: localStorage.getItem('uid'),
  // });

  try {
    if (localStorage.getItem('csrf')) {
      const config = fetchHeaders();
      await http.delete('/api/v1/eames/signin', config);
    }
  } finally {
    localStorage.clear();
    window.location.href = '/login';
  }
}

// @TODO: refactor this function - should be pure
export function checkSession(response: any): any {
  if ([401, 403].includes(response.status)) {
    logout();
    return false;
  }
  return response;
}

export function setCSRFToLocalStorage(csrf: string) {
  localStorage.setItem('csrf', csrf);
}

export function getCSRFFromLocalStorage(): string | null {
  return localStorage.getItem('csrf');
}

export function setUserToLocalStorage(user: User) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getUserFromLocalStorage(): User | null {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
}

/**
 * Expect regex string, return a JS regex expression or null
 */
export function stringToRegExp(str: string): RegExp | null {
  if (!str) return null;
  const match = str.match(/[^/]+/g);
  if (!match) return null;
  const [exp, flags] = match;
  return (exp && new RegExp(exp, flags)) || null;
}

//@TODO - check regexgp
const INPUT_FORMATS_REGEXPS = {
  // eslint-disable-next-line no-useless-escape
  tel: /[^\d\(\)\-\ \+]/g,
  // eslint-disable-next-line no-useless-escape
  number: /[^\d\.]/g,
};

// @TODO: refactor and type `this`
export function handleInputChange(event: any, filterFunc = (s: any): any => s) {
  const { target } = event;

  const customRegexp = target.dataset && stringToRegExp(target.dataset.regex);

  // @ts-ignore
  const defaultRegexp = INPUT_FORMATS_REGEXPS[target.type];
  const inputRegexp = customRegexp || defaultRegexp;

  let { value } = target;
  if (target.type === 'checkbox') {
    value = target.checked;
  } else if (inputRegexp) {
    value = value.replace(inputRegexp, '');
  }

  if (target.dataset && target.dataset.toNumber === 'true') {
    value = Number(value);
  }

  value = filterFunc(value);

  // @ts-ignore
  this.setState({
    form: {
      // @ts-ignore
      ...this.state.form,
      [camelize(target.name)]: value,
    },
  });
}

export function handleStopPropagation(e: SyntheticEvent) {
  try {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Expect one or multiple args as potential classNames
 * Return a space-delimited string concatenating 'truthy' args only
 * Ex. toClassNames('dropdown', true && 'active', false && 'disabled') -> 'dropdown active'
 */
export function toClassNames(...args: (string | null | undefined | false)[]) {
  return args.filter((name) => !!name).join(' ');
}

/**
 * Expect one or multiple arguments as potential classNames
 * Return an object containing a 'className' prop of concatenated 'truthy' args, or empty object
 * Ex. toClassNameObj('dropdown', true && 'active') -> { className: 'dropdown active' }
 */
export function toClassNameObj(...args: (string | null | undefined | false)[]) {
  const className = toClassNames(...args);
  return className ? { className } : {};
}

/**
 * Expect a string (single word), capitalize it
 */
export function capitalize(word: string) {
  return String(word).charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * Expect a string (one or more words), capitalize each word
 * Also, replace any underscores with spaces
 */
export function capitalizeAll(str: string) {
  return String(str)
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

export function isAdminUser(user: User | null | undefined) {
  return user?.role === USER_ROLE_ADMIN;
}

export function isSalesPersonUser(user: User | null | undefined) {
  return user && user.role === USER_ROLE_SALESPERSON;
}

export function isDesignerUser(user: User | null | undefined) {
  return user && user.role === USER_ROLE_DESIGNER;
}

export function showPriceByRole(user: User | null | undefined) {
  return !(
    user &&
    (user.role === USER_ROLE_DESIGNER || user.role === USER_ROLE_SALESPERSON)
  );
}

export function isAllowDesignerMoveProductToProject(
  project: AllProjectsToMoveProductsOption | null | undefined
) {
  // @TODO Clarify condition
  return true;
  // if (
  //   !project ||
  //   project.status === PROJECT_STATUS_CLOSED ||
  //   project.status === PROJECT_STATUS_LOST
  // )
  //   return false;

  // return true;
}

/**
 * Expect a string, return a dash-delimited alphanumeric "slug"
 * Ex. 'Dining Table #207 (New!)' -> 'dining-table-207-new'
 */
export function slugify(str: string) {
  return str
    .replace(/[^a-z0-9 _]+/gi, '') // remove non-alpha chars (except underscore)
    .replace(/ +/g, ' ') // convert any consecutive spaces to single space
    .replace(/[ _]/g, '-') // replace all spaces and underscores with a dash
    .toLowerCase();
}

type HumanizeMode = 'capitalize' | 'capitalizeAll' | 'uppercase';

/**
 * Expect a slug string, return a space-delimited "human-readable" string
 * Apply a string transformation, if supported 'mode' specified
 * Ex. 'date_reserved' -> 'date reserved'
 */
export function humanize(str: string, mode?: HumanizeMode) {
  let result = str
    .replace(/[-_]/g, ' ')
    .replace(/(?<!\d)(\d)/g, ' $1')
    .toLowerCase();
  if (mode === 'capitalize') result = capitalize(result);
  if (mode === 'capitalizeAll') result = capitalizeAll(result);
  if (mode === 'uppercase') result = result.toUpperCase();
  return result;
}

/**
 * Normalize an array of objects into a hash, keyable by 'id' (or other attrib)
 *
 * @param {array} - a list of objects
 * @param {string} [key='id'] - desired attribute to be used as the key
 * @returns {object}
 */

export function normalizeListData<T extends { id: number | string }>(
  list: T[] = [],
  key: keyof T = 'id'
): Record<T['id'] extends number | string ? T['id'] : number | string, T> {
  return list.reduce((accum, obj) => {
    accum[obj[key] as unknown as number | string] = obj;
    return accum;
  }, {} as Record<number | string, T>);
}

/**
 * Coerce value into an array, or return a copy of existing array
 * Default to an empty array if value is null or undefined
 *
 * @param {any} value
 * @returns {array}
 */
export function toArray(value: any): any[] {
  return Array.isArray(value)
    ? [...value]
    : value === null || typeof value === 'undefined'
    ? []
    : [value];
}

/**
 *
 * @param date - date string, or null/empty string for now.
 * @param format - output template.
 * @returns Formatted date string.
 */
export function formatDate(
  date: MomentInput = '',
  format = 'M/D/YYYY'
): string {
  return date ? moment(date).format(format) : moment.parseZone().format(format);
}

export function formatDateByTimeZone(date?: string, format = 'M/D/YYYY') {
  date = moment.parseZone(date ? new Date(date) : '').format(format);
  if (format != 'M/D/YYYY') {
    date += ' (' + moment.tz(moment.tz.guess()).zoneName() + ')';
  }
  return date;
}

export function isEmptyObject<T extends Record<string, unknown>>(
  obj: T
): boolean {
  return !Object.keys(obj).length;
}

/**
 * @TODO
 * @param {Array} fieldKeys - ordered list of keys to check for errors
 * @param {Array} errorsResp - array of error objects from API
 * @returns {{errors: Array, invalid: Array}}
 */
export function parseFormApiErrors(
  fieldKeys: any,
  errorsResp: any,
  renamedFields?: Record<string, string>
) {
  if (Array.isArray(errorsResp)) errorsResp = errorsResp[0]; // API returns an error object within an array

  const invalid: any[] = [];
  const errors = fieldKeys.reduce((accum: any, key: any) => {
    if (errorsResp[key]) {
      const fieldName =
        renamedFields?.[key] ?? capitalize(humanize(decamelize(key)));
      accum = accum.concat(`${fieldName} ${errorsResp[key][0]}`);
      invalid.push(key);
    }
    return accum;
  }, []);

  return { errors, invalid };
}

export type ValidationServerErrorRecord = Record<string, string | string[]>;
export type ServerErrorRecord = ValidationServerErrorRecord | string;

export function serializeApiErrors(errorsResp: ServerErrorRecord[]) {
  return errorsResp.map((error) => {
    if (typeof error === 'string') return error;

    return Object.entries(error).reduce((acc, [key, value]) => {
      const nextValue = Array.isArray(value) ? value.join(', ') : value;
      return acc + `${capitalize(key)} - ${nextValue}`;
    }, '');
  });
}

/**
 * @param {string} key - key to get from or set into
 * @param {any} [value] - value to store (if doing a set), pass nothing for a get
 */
export function useSessionStorage<T>(key: string, value?: T) {
  if (typeof value === 'undefined') {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  return sessionStorage.setItem(key, JSON.stringify(value));
}

/**
 * @TODO
 * Map over an array N elems at a time, apply callback to each chunk, return array of results
 *
 * @param {array} array
 * @param {number} chunkSize
 * @param {function} callback
 * @returns {array}
 */
export function mapArrayChunks(array: any[], chunkSize: number, callback: any) {
  let chunk;
  let index = 0;
  const result = [];

  while (array.length) {
    chunk = array.splice(0, chunkSize);
    result.push(callback(chunk, index));
    index += 1;
  }

  return result;
}

/**
 * @TODO
 * Async promise to continue checking at intervals until the supplied function returns truthy
 *
 * @param {function} fn - function to run at each interval until truthy
 * @param {number} [interval] - interval in ms
 * @param {number} [timeout] - max duration before rejecting
 * @returns fn result or Error
 */
export function untilTrue(fn: any, interval = 10, timeout = 3000) {
  let duration = 0;
  let intervalRef: number | null = null;
  let result;

  return new Promise((resolve, reject) => {
    intervalRef = window.setInterval(() => {
      duration += interval;
      if (duration < timeout) {
        result = fn();
        if (result) {
          window.clearInterval(intervalRef as number);
          resolve(result);
        }
        return;
      }
      window.clearInterval(intervalRef as number);
      reject(new Error('Error: untilTrue timeout!'));
    }, interval);
  });
}

/**
 * @TODO
 * Set up a "throttled" event, to fire no more frequently than browser's refresh rate
 * https://developer.mozilla.org/en-US/docs/Web/Events/scroll
 *
 * @param {string} type - e.g. 'scroll'
 * @param {string} name - throttled event name
 * @param {object} [obj] - e.g. window, element (defaults to window)
 */
export function throttleEvent(type: any, name: any, obj: any) {
  let isTriggered = false;
  const func = () => {
    if (isTriggered) return;
    isTriggered = true;
    requestAnimationFrame(() => {
      obj.dispatchEvent(new CustomEvent(name));
      isTriggered = false;
    });
  };
  obj = obj || window;
  obj.addEventListener(type, func);
}

/**
 * @TODO
 * Returns a "debounced" function, which will execute after specified delay
 * Each invocation resets the timer, so the function will not execute until it stops being called
 * Adapted from: https://davidwalsh.name/function-debounce
 *
 * @param {function} fn - function to debounce
 * @param {number} delay - desired delay (ms)
 * @returns {function}
 */
export function debounce(fn: any, delay = 250) {
  let timeout: number | null;

  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;
    const debouncedFn = function () {
      timeout = null;
      fn.apply(context, args);
    };
    if (timeout) clearTimeout(timeout);
    timeout = window.setTimeout(debouncedFn, delay);
  };
}

/**
 * Format given number as USD currency
 *
 * @param n - input
 * @param round - should round input
 */
export function toCurrency(n: number, round = true): string {
  const config: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
  };
  if (round) {
    config.minimumFractionDigits = 0;
    n = Math.round(n);
  }
  return new Intl.NumberFormat('en-US', config).format(n);
}

/**
 * Format given number to percents
 * @param n
 */
export function toPercents(n: number): string {
  return `${(n * 100).toFixed(2)}%`;
}
/**
 * Truncate any string longer than 'maxChars' with ellipsis
 *
 * @param str
 * @param maxChars
 */
export function truncate(str: string, maxChars: number): string {
  return str.length > maxChars ? `${str.substring(0, maxChars)} …` : str;
}

/**
 * @TODO
 * Check whether specific props between two objects are equal (using strict ===)
 *
 * @param {object} propsA
 * @param {object} propsB
 * @param {array} [keys] -- whitelist of keys to check (or check all keys by default)
 * @return {boolean}
 */
export function isEqualProps<T extends Record<string, unknown>>(
  propsA: T,
  propsB: T,
  keys: Array<keyof T>
) {
  const keyList = keys ? toArray(keys) : Object.keys(propsA);
  if (!keys && keyList.length !== Object.keys(propsB).length) return false; // if checking all, number of entries must match
  // @ts-ignore
  return keyList.every((key) => propsA[key] === propsB[key]);
}

/**
 * TODO
 * Sort table column for numbers and strings
 *
 * @param {boolean} direction
 * @param {object} rows
 * @param {string} sortColumn
 * @param {array} sortNumber
 * @return {object}
 */
export function sortColumns(
  direction: any,
  rows: any,
  sortColumn: any,
  sortNumber: any[] = []
) {
  if (sortNumber.includes(sortColumn)) {
    rows.sort((a: any, b: any) => {
      if (Number(a[sortColumn]) === Number(b[sortColumn])) {
        return 0;
      }
      if (Number(a[sortColumn]) < Number(b[sortColumn])) {
        return 1;
      }
      return -1;
    });
  } else {
    rows.sort((a: any, b: any) =>
      String(a[sortColumn]).localeCompare(String(b[sortColumn]))
    );
  }
  if (direction) {
    rows.reverse();
  }
  return { rows };
}

/**
 * @TODO
 * Map array to options for 'react-select'
 *
 * @param {array} options must be an array of nested elements [{id, name}]
 * @return {array} array of options ready to use with 'react-select'
 */
export const toReactSelectOptions = (options: any[]) => {
  return options
    .filter(Boolean)
    .map(({ id, name }) => ({ label: name, value: id }));
};

export const renderDimentions = (
  width: string | number | null | undefined,
  height: string | number | null | undefined,
  depth: string | number | null | undefined
) => {
  const dimentions = [
    width != null ? `${width}W` : '',
    height != null ? `${height}H` : '',
    depth != null ? `${depth}D` : '',
  ];

  return dimentions.filter(Boolean).join(' x ');
};

export function readFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      if (!fr.result) throw Error(`Cannnot read file ${file.name}`);
      if (typeof fr.result !== 'string') throw Error(`Wromg type of data`);
      const base64 = fr.result?.slice(fr.result.indexOf(',') + 1);
      resolve(base64);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}
