import styled, { css } from 'styled-components';

import IconBullet from 'js/icons/Bullet';

export const tooltipStyles = (message: string) => css`
  &::after {
    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    color: #fff;
    content: '${message}';
    font-weight: 400;
    font-size: 12px;
    padding: 4px 10px;
    position: absolute;
    left: 50%;
    top: 100%;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  &::before {
    bottom: 0;
    border-bottom: 4px solid rgba(0, 0, 0, 0.9);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    content: '';
    left: 50%;
    margin-left: -3px;
    position: absolute;
  }

  &::after,
  &::before {
    display: none;
    z-index: 1;
  }

  &:hover {
    &::after,
    &::before {
      display: block;
    }
  }
`;

export const IconBulletStyled = styled(IconBullet)`
  margin: 0 9px 1px -3px;
  transform: rotate(90deg);
`;

export const DesignerError = styled.div`
  margin-top: 100px;

  .error {
    padding: 100px 50px;
    text-align: center;
    background: #fff;
  }
`;
