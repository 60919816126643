import React, { useMemo } from 'react';
import {
  useQuery,
  type QueryClient,
  type UseQueryOptions,
  type QueryFunction,
} from 'react-query';

import { type SkipFirst } from 'js/types';
import {
  http,
  Query,
  type QueryProps,
  type HttpBaseConfig,
  type Project,
  type InfiniteQueryProps,
  InfiniteQuery,
} from 'js/api';
import {
  COLIS_PER_PRICE_PAGE,
  ColiSortParamsType,
  CustomerOrderLineItem,
} from '../models';

/**
 * GET - /api/v1/eames/customer_order_line_items
 */

// HTTP

export type PaginatedCOLIResponse = {
  data: CustomerOrderLineItem[];
  totalCount?: number;
  totalPages?: number;
};

export type GetCustomerOrderLineItemsXhr =
  | CustomerOrderLineItem[]
  | PaginatedCOLIResponse;

// @TODO Add param to get only rooms names for use in room select
export interface GetCustomerOrderLineItemsXhrParams {
  projectId: Project['id'];
  withoutOtli?: boolean;
  hasPriceListId?: number;
  page?: number;
  perPage?: number;
  roomId?: number | null;
  pagy?: boolean;
  sort?: ColiSortParamsType;
}

export interface GetCustomerOrderLineItemsXhrConfig extends HttpBaseConfig {
  params: GetCustomerOrderLineItemsXhrParams | null;
}

export const getCustomerOrderLineItemsXhr = (
  config: GetCustomerOrderLineItemsXhrConfig
): Promise<GetCustomerOrderLineItemsXhr> =>
  http
    .get<GetCustomerOrderLineItemsXhr>(
      `/api/v1/eames/customer_order_line_items`,
      config
    )
    .then((res) => res.data);

// Query Key

const GET_CUSTOMER_ORDER_LINE_ITEMS = 'customerOrderLineItems';

type GetCustomerOrderLineItemsQueryKeyNamespace =
  typeof GET_CUSTOMER_ORDER_LINE_ITEMS;

export type GetCustomerOrderLineItemsQueryKey = [
  GetCustomerOrderLineItemsQueryKeyNamespace,
  GetCustomerOrderLineItemsXhrParams
];

export const createGetCustomerOrderLineItemsQueryKey = (
  key: SkipFirst<GetCustomerOrderLineItemsQueryKey>
): GetCustomerOrderLineItemsQueryKey => [GET_CUSTOMER_ORDER_LINE_ITEMS, ...key];

// QueryFn

export const getCustomerOrderLineItemsQueryFn: QueryFunction<
  GetCustomerOrderLineItemsXhr,
  GetCustomerOrderLineItemsQueryKey
> = ({ queryKey: [_d, params], signal }) =>
  getCustomerOrderLineItemsXhr({ params, signal });

// create Query Options

export type GetCustomerOrderLineItemsQueryOptions = UseQueryOptions<
  GetCustomerOrderLineItemsXhr,
  unknown,
  GetCustomerOrderLineItemsXhr,
  GetCustomerOrderLineItemsQueryKey
>;

export interface GetCustomerOrderLineItemsQueryOptionsProps {
  params: GetCustomerOrderLineItemsXhrParams;
  options?: Omit<GetCustomerOrderLineItemsQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetCustomerOrderLineItemsQueryOptions = ({
  options,
  params,
}: GetCustomerOrderLineItemsQueryOptionsProps): GetCustomerOrderLineItemsQueryOptions => ({
  queryFn: getCustomerOrderLineItemsQueryFn,
  queryKey: createGetCustomerOrderLineItemsQueryKey([params]),
  ...options,
});

// Hook

export interface UseGetCustomerOrderLineItemsQueryProps {
  options?: GetCustomerOrderLineItemsQueryProps['options'];
  params: GetCustomerOrderLineItemsXhrParams;
}

export const useGetCustomerOrderLineItemsQuery = ({
  params,
  options,
}: UseGetCustomerOrderLineItemsQueryProps) => {
  const queryKey = useMemo(
    () => createGetCustomerOrderLineItemsQueryKey([params]),
    [params]
  );

  return useQuery(queryKey, getCustomerOrderLineItemsQueryFn, options);
};

// Query

export interface GetCustomerOrderLineItemsQueryProps
  extends Omit<
    QueryProps<
      GetCustomerOrderLineItemsXhr,
      Error,
      GetCustomerOrderLineItemsXhr,
      GetCustomerOrderLineItemsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params: GetCustomerOrderLineItemsXhrParams;
}

export type GetCustomerOrderLineItemsQueryResult = Parameters<
  GetCustomerOrderLineItemsQueryProps['children']
>[0];

export const GetCustomerOrderLineItemsQuery = ({
  params,
  ...props
}: GetCustomerOrderLineItemsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetCustomerOrderLineItemsQueryKey([params])}
      queryFn={getCustomerOrderLineItemsQueryFn}
    />
  );
};

// Infinite Query

export interface GetCOLIInfiniteQueryProps
  extends Omit<
    InfiniteQueryProps<
      GetCustomerOrderLineItemsXhr,
      Error,
      GetCustomerOrderLineItemsXhr,
      GetCustomerOrderLineItemsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params: GetCustomerOrderLineItemsXhrParams;
}

export type GetCOLIInfiniteQueryResult = Parameters<
  GetCOLIInfiniteQueryProps['children']
>[0];

export function isPaginatedCOLIResponse(
  data?: GetCustomerOrderLineItemsXhr
): data is PaginatedCOLIResponse {
  return !!data && data.hasOwnProperty('data');
}

export const GetCOLIInfiniteQuery = ({
  params,
  options,
  ...props
}: GetCOLIInfiniteQueryProps) => {
  return (
    <InfiniteQuery
      {...props}
      queryKey={createGetCustomerOrderLineItemsQueryKey([params])}
      queryFn={getCustomerOrderLineItemsQueryFn}
      options={{
        getNextPageParam: (
          _p,
          pages
        ): GetCustomerOrderLineItemsXhrConfig | undefined => {
          const perPage = params?.perPage;
          if (!perPage) return undefined; // Cannot check next page if no params were set up

          const lastPage = pages.length > 1 && pages[pages.length - 1];
          const hasMore =
            lastPage &&
            isPaginatedCOLIResponse(lastPage) &&
            lastPage.data.length < COLIS_PER_PRICE_PAGE;

          // @TODO Replace by this after pagination will be working properly
          // const firstPage = pages.length && pages[0];
          // const hasMore =
          //   firstPage &&
          //   isPaginatedCOLIResponse(firstPage) &&
          //   firstPage.totalPages &&
          //   firstPage.totalPages > pages.length;
          if (!hasMore) return undefined;

          const pageParam: GetCustomerOrderLineItemsXhrParams = {
            ...params,
            page: pages.length,
            perPage,
          };

          return { params: pageParam };
        },
        ...options,
      }}
    />
  );
};

// Query Cache Helpers

export const invalidateGetCustomerOrderLineItemsQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetCustomerOrderLineItemsQueryKey>
) => {
  await client.invalidateQueries(
    createGetCustomerOrderLineItemsQueryKey(keyParams)
  );
};

export const invalidateAllGetCustomerOrderLineItemsQueryData = async (
  client: QueryClient
) => {
  await client.resetQueries(GET_CUSTOMER_ORDER_LINE_ITEMS);
};
