import { type ComponentType } from 'react';
import {
  type MutationFunction,
  useMutation,
  useQueryClient,
  QueryClient,
} from 'react-query';

import {
  GetCustomerOrderLineItemsXhr,
  OrderTripLineItem,
  OrderTripLineItemsByStatus,
  Project,
  createGetCustomerOrderLineItemsQueryKey,
  createGetOrderTripLineItemsByStatusQueryKey,
  http,
  isPaginatedCOLIResponse,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';
import {
  OrderTripPurpose,
  OrderTripStatus,
} from '@interfaces/order_trip.interface';

/**
 * POST - /api/v1/eames/projects/:id/order_trip_line_items
 */

// HTTP

export interface CreateOrderTripLineItemXhrParams {
  orderTripId: number;
  customerOrderLineItemId: number;
  purpose: OrderTripPurpose;
  tripStatus: OrderTripStatus;
}

export interface CreateOrderTripLineItemXhrVariables {
  projectId: number;
  params: CreateOrderTripLineItemXhrParams;
}

export const createOrderTripLineItemXhr = (
  projectId: number,
  params: CreateOrderTripLineItemXhrParams
): Promise<OrderTripLineItem> => //?
  http
    .post<OrderTripLineItem>(
      `/api/v1/eames/projects/${projectId}/order_trip_line_items`,
      params
    )
    .then((res) => res.data);

export const updateCacheForCreateOrderTripLineItemMutation = (
  client: QueryClient,
  createdOtli: Awaited<ReturnType<typeof createOrderTripLineItemXhr>>,
  projectId: Project['id']
) => {
  client.setQueriesData<OrderTripLineItemsByStatus | undefined>(
    createGetOrderTripLineItemsByStatusQueryKey([projectId]),
    (data) => {
      if (!data) return data;

      const statusName = Object.keys(data).find(
        (status) => data[status].orderTripId === createdOtli.orderTripId
      );
      if (!statusName) return data;
      const updatedStatusData = { ...data[statusName] };
      updatedStatusData.otlis.push(createdOtli);

      return {
        ...data,
        [statusName]: updatedStatusData,
      };
    }
  );
  client.setQueriesData<GetCustomerOrderLineItemsXhr | undefined>(
    createGetCustomerOrderLineItemsQueryKey([{ projectId, withoutOtli: true }]),
    (data) => {
      if (!data || isPaginatedCOLIResponse(data)) return data;

      const updatedColis = data.filter(
        (coli) => coli.id !== createdOtli.customerOrderLineItem.id
      );
      return updatedColis;
    }
  );
};

// MutationFn

type CreateOrderTripLineItemMutation = MutationFunction<
  OrderTripLineItem,
  CreateOrderTripLineItemXhrVariables
>;

export const createOrderTripLineItemMutationFn: CreateOrderTripLineItemMutation =
  ({ projectId, params }) => createOrderTripLineItemXhr(projectId, params);

// Hook
export const useCreateOrderTripLineItemMutation = () => {
  const client = useQueryClient();

  return useMutation(createOrderTripLineItemMutationFn, {
    onSuccess: async (otli, { projectId }) => {
      updateCacheForCreateOrderTripLineItemMutation(client, otli, projectId);
    },
  });
};

// With Mutation HOC

const withCreateOrderTripLineItemMutationPropKey =
  'createOrderTripLineItemMutation';

export type WithCreateOrderTripLineItemMutationProps =
  WithMutationPropsByMutation<
    typeof withCreateOrderTripLineItemMutationPropKey,
    CreateOrderTripLineItemMutation
  >;

export function withCreateOrderTripLineItemMutation<
  P extends WithCreateOrderTripLineItemMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateOrderTripLineItemMutationProps>> {
  return withMutation(
    withCreateOrderTripLineItemMutationPropKey,
    createOrderTripLineItemMutationFn
  )(Component as ComponentType<WithCreateOrderTripLineItemMutationProps>);
}
