import { pascalize } from 'humps';
import { User, type GetAllUsersXhr } from './models';
import { type Option } from 'js/components/partial/Forms/Select';

export const combineAdminsAndDesignersToOptions = (
  users?: GetAllUsersXhr
): Option<number>[] => {
  if (!users) return [];
  return [...users.admins, ...users.designers].map((user) => ({
    label: user.fullName,
    value: user.id,
  }));
};

export const userToOption = (user: User): Option<number> => ({
  label: user.fullName,
  value: user.id,
});

export const concatAllUsersToOptions = (
  users?: GetAllUsersXhr
): Option<number>[] => {
  if (!users) return [];
  const usersGroups = Object.keys(users) as (keyof GetAllUsersXhr)[];
  const options = usersGroups.reduce((acc, groupName, index) => {
    acc.push({
      label: `----  ${pascalize(groupName)}  ----`,
      value: -index,
    });
    acc.push(...users[groupName].map(userToOption));
    return acc;
  }, [] as Option<number>[]);
  return options;
};
