import React from 'react';
import { type QueryFunction, type QueryClient } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Product, type SingleProduct } from '../models';

/**
 * GET - /api/v1/eames/items/:id
 */

// HTTP

export type GetProductXhr = SingleProduct;

export const getProductXhr = (
  productId: Product['id'],
  config: HttpBaseConfig = {}
): Promise<SingleProduct> =>
  http
    .get<GetProductXhr>(`/api/v1/eames/items/${productId}`, config)
    .then((res) => res.data);

// Query Key

const GET_PRODUCT_QUERY_KEY_NAMESPACE = 'getProduct';

type GetProductQueryKeyNamespace = typeof GET_PRODUCT_QUERY_KEY_NAMESPACE;

export type GetProductQueryKey = [
  GetProductQueryKeyNamespace,
  number /* product id */
];

export const createGetProductQueryKey = (
  key: SkipFirst<GetProductQueryKey>
): GetProductQueryKey => [GET_PRODUCT_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getProductQueryFn: QueryFunction<
  SingleProduct,
  GetProductQueryKey
> = ({ queryKey: [_d, productId] }) => getProductXhr(productId);

// Query

export interface GetProductQueryProps
  extends Omit<
    QueryProps<SingleProduct, unknown, SingleProduct, GetProductQueryKey>,
    'queryFn' | 'queryKey'
  > {
  productId: number;
}

export type GetProductQueryResult = Parameters<
  GetProductQueryProps['children']
>[0];

export const GetProductQuery = ({
  productId,
  ...props
}: GetProductQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProductQueryKey([productId])}
      queryFn={getProductQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateProductQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetProductQueryKey>
) => {
  await client.invalidateQueries(createGetProductQueryKey(keyParams));
};
