import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/room_product_items/:id
 */

// HTTP

// Hardcoded body with relevant status id to release item reservation
const body = {
  roomProductItem: {
    status: 2,
  },
} as const;

export const releaseProjectItemReservationXhr = (
  itemId: number
): Promise<unknown> => mockResponse({});
// http.put<unknown>(`/inventory/v1/room_product_items/${itemId}`, body);

// MutationFn

type ReleaseProjectItemReservationMutation = MutationFunction<
  unknown,
  number /* itemId */
>;

export const releaseProjectItemReservationMutationFn: ReleaseProjectItemReservationMutation =
  (itemId) => releaseProjectItemReservationXhr(itemId);

// With Mutation HOC

const withReleaseProjectItemReservationMutationPropKey =
  'releaseProjectItemReservationMutation';

export type WithReleaseProjectItemReservationMutationProps =
  WithMutationPropsByMutation<
    typeof withReleaseProjectItemReservationMutationPropKey,
    ReleaseProjectItemReservationMutation
  >;

export function withReleaseProjectItemReservationMutation<
  P extends WithReleaseProjectItemReservationMutationProps
>(
  Component: ComponentType<P>
): ComponentType<
  Omit<P, keyof WithReleaseProjectItemReservationMutationProps>
> {
  return withMutation(
    withReleaseProjectItemReservationMutationPropKey,
    releaseProjectItemReservationMutationFn
  )(Component as ComponentType<WithReleaseProjectItemReservationMutationProps>);
}
